export type ButtonAction  = {
	icon_url?: string;
  text: string;
  callback: (args: any[]) => void;
	callbackId?: string;
}

export type Buttons = {
	[key: string] : ButtonAction[]
}

export type NavbarItem = {
	id: string;
	icon_url: string;
	text: string;
	type: string; // group / item
	parentId?: string; 
	callback: (args: any[]) => void;
	callbackId?: string;
}

export type InitializationOptions = {
  buttons: Buttons,
	navbar: NavbarItem[]
}

export type ModalOptions = {
	title: string;
	url: string;
	maxWidth?: string;
	height?: string;
	autoClose: boolean;
	callback: (args: any[]) => void;
	callbackId?: string;
}

export enum AlertVariant {
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning'
}

export type AlertOptions = {	
	message: string,
	variant: AlertVariant
}

export type ConfirmDialogOptions = {	
	title: string,
	text: string,
	callback: (confirm: boolean) => void;
	callbackId?: string;
}

export enum Command {
  INITIALIZE = 'initialize',
  BUTTONS_ACTION = 'button_action',
  OPEN_MODAL = 'openModal',
  CLOSE_MODAL = 'closeModal',
  OPEN_ALERT = 'openAlert',
  OPEN_CONFIRM_DIALOG = 'openConfirmDialog',
  GET_INFO_USER = 'getInfoUser',
	GET_INFO_CHANNELS = 'getInfoChannels',
	OPEN_PAGE = 'openPage'
}

export type Args<T extends Command> = {
	[Command.INITIALIZE]: InitializationOptions;
  [Command.OPEN_ALERT]: AlertOptions;
  [Command.BUTTONS_ACTION]: void;
  [Command.OPEN_MODAL]: ModalOptions;
	[Command.OPEN_CONFIRM_DIALOG]: ConfirmDialogOptions;
  [Command.CLOSE_MODAL]:void;
  [Command.GET_INFO_USER]:void;
	[Command.GET_INFO_CHANNELS]: void;
	[Command.OPEN_PAGE]: OpenPageOptions;
}[T];

export type Payload<T extends Command> = {
	command: T;
	args: Args<T>;
};

export type CommandResponse<T extends Command> = {
	[Command.INITIALIZE]: void;
	[Command.OPEN_ALERT]: void;
	[Command.BUTTONS_ACTION]: void;
	[Command.OPEN_MODAL]: void;
	[Command.OPEN_CONFIRM_DIALOG]: void;
	[Command.CLOSE_MODAL]: void;
  [Command.GET_INFO_USER]: {
		userId: string,
		systemKey: string
	};
	[Command.GET_INFO_CHANNELS]: any[]
	[Command.OPEN_PAGE]: void;
}[T];

export type MessageChannelCommandResponse<T extends Command> = {
	data?: CommandResponse<T>;
	error?: string;
};

export enum Event {
	INITIALIZE= 'initialize',
	CLOSE_CUSTOM_MODAL = 'close_custom_modal',
  CALLBACK = 'callback'
}

export enum MessageType {
	COMMAND = 'command',
	LISTENER = 'listener',
	TRACK = 'track',
}

export type EventResponse<T extends Event> = {
	error?: string;
	data?: {
		[Event.INITIALIZE]: void;
		[Event.CLOSE_CUSTOM_MODAL]: void;
    [Event.CALLBACK]: void
	}[T];
};

export type ExecuteCommandArgs<T extends Command> = Args<T> extends void ? [] : [Args<T>];

export type OpenPageOptions = {
	url: string;
}