import { AlertOptions, Command, CommandResponse, ConfirmDialogOptions, Event, EventResponse, ExecuteCommandArgs, InitializationOptions, MessageChannelCommandResponse, MessageType, ModalOptions, OpenPageOptions, Payload } from "./Types";
import { getExtensionId, guidGenerator } from "./utils";

const eventKeys = Object.values(Event);
const commandKeys = Object.values(Command);

class WlExtension {
  private readonly identifier: string;
  private initialized: boolean;
  private window: Window;
	private channel: MessageChannel;
  private callbacks:any = {};

  constructor() {
		this.initialized = false;
		this.window = window.parent;
		this.identifier = getExtensionId()


		this.init();
  }

	private init(){
		this.channel = new MessageChannel();

		this.channel.port1.onmessage = ({ data }: { data: any }) => {
			if(!data || !data.command) return;
      if(data && data.command == Event.CALLBACK){
				if(typeof this.callbacks[data.callbackId] != 'function') return;

        this.callbacks[data.callbackId](data.payload)
      }
		};
	}

  private postMessage<K extends Command>(payload: Payload<K>, targetOrigin = '*'): Promise<CommandResponse<K>> {
		return new Promise((resolve, reject) => {
			const channel = new MessageChannel();
			const message = {
				...payload,
				id: this.identifier,
			};

			channel.port1.onmessage = ({ data: response }: { data: MessageChannelCommandResponse<K> }) => {
				channel.port1.close();
				const { error, data } = response;
				
				if (error) {
					reject(new Error(error));
				} else {
					resolve(data);
				}
			};

			console.log(message);
			this.window.postMessage(JSON.parse(JSON.stringify(message)), targetOrigin, [channel.port2]);
		});
	}

	private bindCallback(callback: Function) : string {
		const callId = guidGenerator();
		this.callbacks[callId] = callback;

		return callId;
	}

  public async getInfoUser(): Promise<CommandResponse<Command.GET_INFO_USER>>{
    return this.postMessage({
			command: Command.GET_INFO_USER,
			args: null,
		});
  }

  public async getInfoChannels(): Promise<CommandResponse<Command.GET_INFO_CHANNELS>>{
    return this.postMessage({
			command: Command.GET_INFO_CHANNELS,
			args: null,
		});
  }

	public async modal(options: ModalOptions): Promise<void> {
		const callId = this.bindCallback(options.callback);
		const channel = new MessageChannel();

		channel.port1.onmessage = ({ data }: { data: { payload: any, error: any}}) => {
			if (data.error) {
				channel.port1.close();
			}

			if(options.callback)
				options.callback(data?.payload);
		};

		const message = {
			command: Command.OPEN_MODAL,
			args:{
				autoClose: true,
				...options,
				callbackId: callId
			},
		}
		this.window.postMessage(JSON.parse(JSON.stringify(message)), `*`, [channel.port2]);
	}

	public async closeModal(args: any):  Promise<void>{
		const message = {
			command: Command.CLOSE_MODAL,
			args:args
		}

		this.window.postMessage(JSON.parse(JSON.stringify(message)), `*`);
	}

	public async alert(options: AlertOptions): Promise<void> {
		return this.postMessage({
			command: Command.OPEN_ALERT,
			args: options,
		});
	}

	public async confirmDialog(options: ConfirmDialogOptions): Promise<void> {
		const callId = this.bindCallback(options.callback);
		const channel = new MessageChannel();

		channel.port1.onmessage = ({ data }: { data: { payload: { confirm: boolean }, error: any}}) => {
			if (data.error) {
				channel.port1.close();
			}

			options.callback(data.payload.confirm);
		};

		const message = {
			command: Command.OPEN_CONFIRM_DIALOG,
			args: {
				...options,
				callbackId: callId
			},
		};

		this.window.postMessage(JSON.parse(JSON.stringify(message)), `*`, [channel.port2]);
	}

	public async openPage(options: OpenPageOptions): Promise<void> {
		return this.postMessage({
			command: Command.OPEN_PAGE,
			args: options,
		});
	}

	public async initialize(options: InitializationOptions): Promise<this> {
		//
		// bind callback buttons
		//
		if(options.buttons){
			Object.keys(options.buttons)
      .forEach((key:any) =>{
        options.buttons[key] = options.buttons[key].map((button)=>{
          return {
            ...button,
            callbackId: this.bindCallback(button.callback)
          }
        })
      })
		}

		//
		// bind callback navbar
		//
		if(options.navbar){
			options.navbar = options.navbar.map((nav) => {
				return {
					...nav,
					callbackId: this.bindCallback(nav.callback)
				}
			});
		}

		const message = {
			command: Command.INITIALIZE,
			args: { ...options },
			id: this.identifier,
		};

		this.window.postMessage(JSON.parse(JSON.stringify(message)), `*`, [this.channel.port2]);

		this.initialized = true;
		return this;
	}
}

declare global {
  interface Window { WlExtension: WlExtension}
}

window.WlExtension = new WlExtension();