import { getCookie, setCookie } from "./cookies";


export const guidGenerator = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
} 

export const getExtensionId = ()=>{
  const querystringParams = new URLSearchParams(window.location.search);

  let extensionId = querystringParams.get('id');

  if(extensionId){
    setCookie('extensionId',extensionId);
  }else{
    extensionId = getCookie('extensionId');
  }

  return extensionId
}